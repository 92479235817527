import {setDefaultFormSet} from '@/modules/routeHooks'

const npsRoutes = [
    {
        path: '/:shortName/feedback',
        name: 'nps-feedback',
        component: () => import(/* webpackChunkName: "DynamicFormView" */ '@/views/shared/DynamicFormView'),
        meta: {
            layout: 'empty',
            headerType: 'nps-header',
            // headerType: 'survey-header',
            urlRoute: 'feedback'
        },
        beforeEnter: setDefaultFormSet
    },
    {
        path: '/:shortName/NPS',
        name: 'nps-view',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta: {
            headerType: 'nps-header'
        },
        component: () => import(/* webpackChunkName: "nps-view" */ '@/views/shared/NpsView')
    },
    {
        path: '/:shortName/NPS/:formSetId',
        name: 'nps-3question-view',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "nps-view" */ '@/views/shared/NpsView')
    },
    {
        path: '/:shortName/NPSC',
        name: 'npsc-view',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "nps-view" */ '@/views/shared/NpsView')
    },
    // TODO: add back if we need this
    // {
    //     path: '/:shortName/NPSC/:formSetId',
    //     name: 'npsc-3question-view',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "nps-view" */ '@/views/shared/NpsView')
    // },
    {
        path: '/:shortName/NPS/ThankYou',
        name: 'nps-thank-you', //NPS url
        component: () => import(/* webpackChunkName: "form-view" */ '@/views/shared/ThankYouView'),
        meta: {
            layout: 'empty',
            headerType: 'nps-thankyou'
        },
    },
    {
        path: '/:shortName/NPSC/ThankYou',
        name: 'npsc-thank-you',
        component: () => import(/* webpackChunkName: "form-view" */ '@/views/shared/ThankYouView'),
        meta: {
            layout: 'empty',
            headerType: 'nps-thankyou'
        },
    },

    // thank you pages just for custom NPS pages
    {
        path: '/:shortName/NPS-QR/ThankYou',
        name: 'npsc-thank-you-qr-code',
        component: () => import(/* webpackChunkName: "form-view" */ '@/views/shared/ThankYouView'),
        meta: {
            layout: 'empty',
            headerType: 'nps-thankyou'
        },
    },
    {
        path: '/:shortName/NPS-B/ThankYou',
        name: 'npsc-basic-thank-you',
        component: () => import(/* webpackChunkName: "form-view" */ '@/views/shared/ThankYouView'),
        meta: {
            layout: 'empty',
            headerType: 'nps-thankyou'
        },
    },
]

for (const route of npsRoutes) {
    if (route.beforeEnter) continue

    route.beforeEnter = (to, from, next) => {
        to.params.kiosk = false
        // getting the value PatientSurvey or NPS so we know which form to go back to
        // need to get the path value at index `2` because with split, the 0 index is the first '/'
        if (to.path.indexOf('thank-you') === -1) {
            if (!to.params.routeType) to.params.routeType = to.path.split('/')[2].toLowerCase()
        }
        let routeType = to.path.split('/').at(3)?.toLowerCase()
        if (routeType === 'feedback') {
            routeType = 'nps'
            to.params.redirectTo = 'nps-feedback'
        }
        next(to.params)
    }
}

export {
    npsRoutes
}
