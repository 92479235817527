import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { from } from 'apollo-link'
import ApolloMiddleware from '@/utils/apollo.middleware'
import {createApolloClient, restartWebsockets} from 'vue-cli-plugin-apollo/graphql-client'
import * as Sentry from '@sentry/vue'

// Install the vue plugin
Vue.use(VueApollo)

const APOLLO_TOKEN = 'apollo-token'
let apolloProvider
let client = null

// Config
const defaultOptions = {
    // You can use `wss` for secure connection (recommended in production)
    // Use `null` to disable subscriptions
    // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
    wsEndpoint: null,
    // LocalStorage token
    tokenName: APOLLO_TOKEN,
    // Enable Automatic Query persisting with Apollo Engine
    persisting: false,
    // Use websockets for everything (no HTTP)
    // You need to pass a `wsEndpoint` for this to work
    websocketsOnly: false,
    // Is being rendered on the server?
    ssr: false,

    // Set to false so that we can add our own custom default httplink in the 'link' method below.
    defaultHttpLink: false,

    // Override default apollo link
    // note: don't override httpLink here, specify httpLink options in the
    // httpLinkOptions property of defaultOptions.
    link: from(ApolloMiddleware),
}

// Call this in the Vue app file
function getOrCreateProvider(options = {}) {
    // if we already have it, then return it
    if (apolloProvider) return apolloProvider

    // Create apollo client
    const { apolloClient, wsClient } = createApolloClient({
        ...defaultOptions,
        ...options
    })
    client = apolloClient
    client.wsClient = wsClient

    // Create vue apollo provider
    if (!apolloProvider) {
        apolloProvider = new VueApollo({
            defaultClient: client,
            async errorHandler(error) {
                // eslint-disable-next-line no-console
                console.log(
                    '%cError',
                    'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
                    error.message
                )
                Sentry.captureException(error);
                // const client = apolloProvider.defaultClient
                // await client.queryManager.fetchQueryRejectFns
                // await client.clearStore()
            }
        })
    }

    return apolloProvider
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
    if (typeof localStorage !== 'undefined' && token) {
        localStorage.setItem(APOLLO_TOKEN, token)
    }
    if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
    try {
        await apolloClient.resetStore()
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log('%cError on cache reset (login)', 'color: orange;', e.message)
    }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem(APOLLO_TOKEN)
    }
    if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
    try {
        await apolloClient.resetStore()
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
    }
}

export {
    client,
    getOrCreateProvider
}
