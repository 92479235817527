// Modal state mutations
export const MODAL_IS_VISIBLE = 'MODAL_IS_VISIBLE'
export const MODAL_DATA = 'MODAL_DATA'
export const MODAL_COMPONENT_NAME = 'MODAL_COMPONENT_NAME'

export const SET_TENANT_ID = 'SET_TENANT_ID'
export const SET_FORM_TO_SAVE = 'SET_FORM_TO_SAVE'

export const SET_EDITING_FORM = 'SET_EDITING_FORM'
export const SET_ELEMENT_TO_EDIT = 'SET_ELEMENT_TO_EDIT'
export const SET_FORM_TITLE = 'SET_FORM_TITLE'
export const SET_TENANT_LOGO = 'SET_TENANT_LOGO'
export const SET_CATEGORY = 'SET_CATEGORY'

export const SET_IFRAME_MESSAGE = 'SET_IFRAME_MESSAGE'
export const SET_IFRAME_SOURCE = 'SET_IFRAME_SOURCE'

export const SET_CONSTANTS = 'SET_CONSTANTS'

export const SET_READ_ONLY = 'SET_READ_ONLY'
export const SET_STATIC_OBJECT = 'SET_STATIC_OBJECT'
export const SET_IS_FULL_SURVEY = 'SET_IS_FULL_SURVEY'
export const SET_THANK_YOU_QUERY_PARAMS = 'SET_THANK_YOU_QUERY_PARAMS'
export const SET_IS_EXTERNAL = 'SET_IS_EXTERNAL'
export const SET_PARENT_TENANT_ID = 'SET_PARENT_TENANT_ID'
export const SET_PREVIOUS_ROUTE = 'SET_PREVIOUS_ROUTE'

export const SET_QUESTION_MAPPING = 'SET_QUESTION_MAPPING'
export const UPDATE_QUESTION_MAPPING = 'UPDATE_QUESTION_MAPPING'
export const SET_TENANT = 'SET_TENANT'
export const SET_FORM_PAGE_HEADER_TYPE = 'SET_FORM_PAGE_HEADER_TYPE'

export const ADD_TO_SAVE_TIMEOUT_QUEUE = 'ADD_TO_SAVE_TIMEOUT_QUEUE'
export const CLEAR_SAVE_TIMEOUT_QUEUE = 'CLEAR_SAVE_TIMEOUT_QUEUE'
export const UPDATE_IS_SAVING = 'UPDATE_IS_SAVING'


export const SET_SHOW_TENANT_BRANDING = 'SET_SHOW_TENANT_BRANDING'
export const SET_IS_KIOSK = 'SET_IS_KIOSK'
