import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'
import {SURVEY_ENGINE_API_HTTP} from '@/modules/constants'

class TenantService {
    constructor () {
        this.url = `${SURVEY_ENGINE_API_HTTP}/tenants`
    }

    async getTenantByShortName(shortName) {
        let tenant = null
        try {
            const { data } = await axios.get(`${this.url}/by-shortname/${shortName}`)
            tenant = { ...camelcaseKeys(data, { deep: true }) }
        } catch (e) {
            console.log('error from call', e)
        }
        return tenant
    }

    async getTenantById(tenantId) {
        let tenant = null
        try {
            const { data } = await axios.get(`${this.url}/${tenantId}`)
            tenant = { ...camelcaseKeys(data, { deep: true }) }
        } catch (e) {
            console.log('error from call', e)
        }
        return tenant
    }
}

export const tenantService = new TenantService()
