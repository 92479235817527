<template>
    <v-app
        :class="getClasses()"
    >
        <!--If NPS form, show new header-->
        <div v-if="isMainHeader" class="mb-24">
            <div class="nps-background" :class="{'default-bg-image': isDefaultTheme}"></div>
            <div class="nps-container nps-header-container">
                <img
                    v-if="!loadingStyle || isKiosk"
                    :src="headerImage"
                    class="ultimateq-header z-10"
                    :class="{'default-theme-container': isDefaultTheme}"
                />
            </div>
            <div class="nps-header-space"></div>
            <FormTitle :title="tenantName" />
        </div>
<!--        <div v-else-if="isSurveyHeader && isDefaultTheme">-->
        <div v-else-if="isSurveyHeader" class="mb-10">
            <div class="nps-background" :class="{'default-bg-image': isDefaultTheme}"></div>
            <div class="nps-container nps-header-container">
                <img
                    v-if="!loadingStyle"
                    :src="headerImage"
                    class="ultimateq-header z-10"
                    :class="{'default-theme-container': isDefaultTheme, 'ml-56 mt-8': !isDefaultTheme}"
                />
            </div>
            <div class="nps-header-space" :class="{'mb-8': isDefaultTheme}"></div>
            <FormTitle :title="tenantName" />
            <div class="nps-container pt-8">
                <div v-if="!showFormTitle">Please rate each statement.</div>
            </div>
        </div>

        <!--end NPS header-->

        <!--If NPS form's thank you page-->
        <!-- I put in a small hack to give the empty container height-->
        <div
            v-if="isThankYouHeader"
            class="nps-container nps-header-container h-20"
            id="thankyouheader"
        >
            <!--            <img src="@/assets/images/ultimateq-thankyou-header.png" class="w-full h-auto"/>-->
        </div>
        <!--End thank you-->
        
        <v-main
            v-if="!isMainHeader && !isThankYouHeader"
            :class="{ 'items-start md:items-center': isQpa, 'h-full': isMainHeader, 'h-auto': isSurveyHeader }"
        >
            <div class="flex flex-col layoutHeight">
                <component :is="layout">
                    <SwitchLayout />
                </component>
            </div>
        </v-main>
        <div v-else class="mb-32">
            <!--If NPS form -->
            <component :is="layout">
                <SwitchLayout />
            </component>
        </div>

        <!--If NPS form, show footer logo-->
        <div
            v-if="isMainHeader || isThankYouHeader || isSurveyHeader"
            :class="{ 'pointer-events-none relative mt-6 bottom-0 left-0 right-0 w-full mb-8': isMainHeader, 'block mt-6 pb-6': isSurveyHeader }"
        >
            <img
                :src="footerImage"
                class="text-center nps-footer h-auto m-auto"
                alt="UltimateQ"
            />
        </div>
        <component :is="modalActive" class="absolute" />
    </v-app>
</template>

<script>
import SwitchLayout from "@/views/SwitchLayout"
import { onMounted, computed, watch, ref } from "@vue/composition-api"
import { createNamespacedHelpers } from "vuex-composition-helpers"
import Modal from "@/components/shared/Modal"
import FormTitle from "@/components/basic/FormTitle"
import DEFAULT_HEADER_IMAGE from '@/assets/images/ultimateq-header-img.png'
import DEFAULT_FOOTER_IMAGE from '@/assets/images/ultimateq-logo-2.png'
import {useTenantStylesStore} from '@/stores/useTenantStylesStore'
import {storeToRefs} from 'pinia'
import { useScreenOrientation } from '@vueuse/core'
import {useTenantStore} from '@/stores/useTenantStore'

const { useActions, useGetters } = createNamespacedHelpers("")

export default {
    name: "App",
    components: { SwitchLayout, Modal, FormTitle },
    metaInfo: {
        title: "Patient Survey",
        meta: [
            { charset: "utf-8" },
            {
                name: "viewport",
                content:
                    "user-scalable=0, width=device-width, height=device-height, initial-scale=1, maximum-scale=1.0, minimum-scale=1.0",
            },
        ],
    },
    setup(_, { root }) {
        const device = root?.['$device']
        const { orientation } = useScreenOrientation()
        const firstLoad = ref(true)
        const tenantStore = useTenantStore()
        const { computedTenant } = storeToRefs(tenantStore)
        const tenantName = computed(() => computedTenant.value?.name)
        // const isLandscape = ref(false)

        // watch(orientation, () => {
        //     console.log('====== orientation is ', orientation.value)
        //     isLandscape.value = orientation.value?.indexOf('portrait') > -1
        // })
        // // // const orientation = ref('')
        const isLandscape = computed(() => {
            console.log('====== orientation is ', orientation.value)
            // for some reason, when the ipad changes orientation, the orientation comes as 'portait' when it is actually landscape
            return orientation.value?.indexOf('portrait') > -1
        })
        const {
            isVisible,
            isExternal,
            tenant,
            formHeaderType,
            showTenantBranding,
            isKiosk
        } = useGetters([
            "isVisible",
            "isExternal",
            "tenant",
            "formHeaderType",
            "showTenantBranding",
            "isKiosk"
        ])
        const tenantStylesStore = useTenantStylesStore()
        const { loadingStyle, styleObj, isDefaultTheme, styleTheme, showFormTitle, footerImageUrl, logoImage } = storeToRefs(tenantStylesStore)
        const headerImage = computed(() => logoImage.value || styleObj.value?.headerImageUrl || DEFAULT_HEADER_IMAGE)
        const footerImage = computed(() => footerImageUrl.value || DEFAULT_FOOTER_IMAGE)
        const { fetchStaticChoices } = useActions(["fetchStaticChoices"])
        const layout = ref("default-layout")

        onMounted(() => {
            fetchStaticChoices()
            if (isQpa && !isExternal.value) layout.value = `${getPrefix(true)}-layout`
            firstLoad.value = false
        })

        const isSurveyHeader = computed(
            () => formHeaderType.value === 'survey-header'
        )
        const isMainHeader = computed(
            () => formHeaderType.value === 'nps-header'
        )
        const isThankYouHeader = computed(
            () => formHeaderType.value === 'nps-thankyou'
        )
        const modalActive = computed(() => {
            return isVisible.value ? "Modal" : ""
        })

        const getPrefix = (hideHeader) => {
            return hideHeader ? "empty" : "default"
        }

        const getClasses = () => {
          let retClasses = ''
          if (device.mobile) retClasses += 'app-mobile '
          if (isExternal.value) retClasses += 'ted-branding '
          if (isQpa.value) retClasses += 'tenant-branding '
          if (isDefaultTheme.value) retClasses += 'default-theme '
          if (styleTheme.value) retClasses += styleTheme.value

          return retClasses
        }

        watch(
            () => isExternal.value,
            (newValue) => {
                const prefix = getPrefix(newValue || isQpa)
                layout.value = `${prefix}-layout`
            }
        )

        const isQpa = computed(() => {
            return (
                tenant.value?.parentTenant?.name?.toLowerCase().indexOf("qpa") > -1 ||
                showTenantBranding.value
            )
        })

        return {
            layout,
            isVisible,
            modalActive,
            isExternal,
            isQpa,
            isMainHeader,
            isThankYouHeader,
            isSurveyHeader,
            headerImage,
            loadingStyle,
            isDefaultTheme,
            showFormTitle,
            footerImage,
            isLandscape,
            firstLoad,
            tenantName,
            isKiosk,
            getClasses
        }
    },
}
</script>
