import Vue from 'vue'
import * as Sentry from '@sentry/vue'

if (process.env.VUE_APP_LOG_SENTRY === 'true' && process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        environment: process.env.VUE_APP_SENTRY_ENV,
        debug: process.env.VUE_APP_SENTRY_DEBUG === 'true'
    })
}
