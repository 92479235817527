import Vue from 'vue'
import Vuetify, {
    VSelect, VTextField, VForm, VMenu, VTimePicker, VDatePicker,
    VSimpleCheckbox, VTextarea, Ripple, VCombobox, VAutocomplete,
    VRadio, VRadioGroup, VCheckbox, VBtnToggle
} from 'vuetify/lib'
import light from './theme/theme'
import EditableDatePicker from '@/components/basic/EditableDatePicker'

Vue.use(Vuetify, {
    components: {
        VSelect, VTextField, VForm, VMenu, VTimePicker, VDatePicker, VBtnToggle,
        VSimpleCheckbox, VTextarea, VCombobox, VAutocomplete, VRadio, VRadioGroup,
        VCheckbox, EditableDatePicker
    },
    directives: { Ripple }
})

// TODO: remove this workaround when there is a fix for Vue2 or when upgrading to Vue3
const ignoredMessage = "The .native modifier for v-on is only valid on components but it was used on <svg>.";

Vue.config.warnHandler = (message, vm, componentTrace) => {
    if (message !== ignoredMessage) {
        console.error(message + componentTrace);
    }
};

const opts = {
    theme: {
        themes: {
            light: light
        }
    },
    icons: {
        iconfont: 'faSvg'
    }
}

export default new Vuetify(opts)
