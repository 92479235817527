<template lang="pug">
    .m-auto.w-full.h-full
        h1(class="text-black mb-2 text-xl lg:text-2xl text-center") Would you like to continue?

        .px-4.h-full

            .text-center(class="min-h-3/4")
                | We have noticed you have been idle for sometime. 
                div Please click 'dismiss' below to continue.
            .text-center.text-red
                BaseTimer(
                    :seconds="time"
                )
            .flex.flex-row.justify-center.mt-4
                v-btn(
                    depressed
                    color="success"
                    class="mr-4 w-40"
                    @click="dismissHandler"
                ) dismiss
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import BaseTimer from '@/components/partials/BaseTimer'
import {createNamespacedHelpers} from 'vuex-composition-helpers'
import {MODAL_DISMISS_TIMEOUT} from '@/modules/constants'
import {goBackToPresurvey} from '@/modules/forms'
import { useKioskFormStore } from '@/stores/useKioskFormStore'
import { useTimeout } from '@/composables/thank-you'


const { useActions, useGetters } = createNamespacedHelpers('')

export default {
    name: 'ModalShowIdleTimeout',
    components: { BaseTimer },
    setup(_, { root }) {
        const router = root.$router
        const route = root.$route
        const time = ref(Number(MODAL_DISMISS_TIMEOUT))
        const { setModalClose } = useActions(['setModalClose'])
        const { isKiosk } = useGetters(['isKiosk'])
        const kioskFormStore = useKioskFormStore()
        const { resetIdleTimeout } = kioskFormStore
        const variables = ref({})
        const shortNameRoute = ref(!!route.params.shortName)

        const {
            params,
            shortName,
            previousRoute
        } = useTimeout(variables)
        params.value = route.query
        shortName.value = shortNameRoute.value

        // const isKiosk = ref(!!route.params.kiosk)
        const dismissHandler = () => {
            // root.$emit('reset-time-out')
            resetIdleTimeout()
            time.value = Number(MODAL_DISMISS_TIMEOUT)
            setModalClose()
        }

        watch(() => time.value, () => {
            if (time.value <= 0) {
                let routeName = previousRoute.value
                //TODO: make the below a constant for now
                if (isKiosk.value) routeName = 'ShortName-Kiosk-DynamicFormView'
                else routeName = 'PatientSurveyView'
                goBackToPresurvey(router, isKiosk.value, shortName.value, params.value, routeName)
                dismissHandler()
                return
            }
            setTimeout(() => {
                time.value--
            }, 1000)
        }, {immediate: true})

        return {
            time,
            dismissHandler
        }
    }
}
</script>
