<template lang="pug">
    .m-auto.w-full.h-full
        //h1(class="text-black mb-2 text-xl lg:text-2xl text-center") Would you like to continue?
        .px-4.h-full.flex.items-center.justify-center
            .text-center(class="min-h-3/4")
                fa-icon(icon="spinner" spin class="mr-2")
                | Printing form, please wait...
</template>

<script>
export default {
    name: 'ModalShowPrinting'
}
</script>
