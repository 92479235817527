export const getTenantIdOrCompare = (shortName, tenantId, parentId) => {
    const tenantIdOrCompare = [{
        short_name: {
            _eq: shortName
        }
    }]
    if (tenantId) {
        tenantIdOrCompare.push({
            tenant_id: {
                _eq: tenantId
            }
        })
    }
    if (parentId) {
        tenantIdOrCompare.push({
            tenant_id: {
                _eq: parentId
            }
        })
    }

    return tenantIdOrCompare
}
