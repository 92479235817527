import {defineStore} from 'pinia'
import {computed, ref} from '@vue/composition-api'

const DEFAULT_STYLE = 'dark'
export const useTenantStylesStore = defineStore('tenant-styles', () => {
    const _loadingStyle = ref(true)
    const styleObj = ref({})
    const _headerImage = ref({})

    const headerImage = computed(() => _headerImage.value)
    const loadingStyle = computed(() => _loadingStyle.value)
    const styleTheme = computed(() => styleObj.value?.theme || DEFAULT_STYLE)
    const isDefaultTheme = computed(() => styleObj.value?.theme === DEFAULT_STYLE || !styleObj.value?.theme)
    const openAllAccordions = computed(() => styleObj.value?.styles?.openAllAccordions)
    const showFormTitle = computed(() => styleObj.value?.styles?.showFormTitle)
    // the reason I have it !== false is cause this could be null, undefined or true, we only want to skip the stats
    // load if it's explicitly set as false
    const loadFormStatsOnForm = computed(() => styleObj.value?.styles?.loadFormStatsOnForm !== false && styleObj.value?.styles?.loadFormStatsOnForm !== undefined)
    const indentQuestionGroupings = computed(() => !!styleObj.value?.styles?.indentQuestionGroupings)
    const footerImageUrl = computed(() => styleObj.value?.styles?.footerImageUrl)
    const defaultSubmitButtonText = computed(() => styleObj.value?.styles?.defaultSubmitButtonText)
    const disableForms = computed(() => styleObj.value?.styles?.disableForms)
    const singleAccordionOpen = computed(() => styleObj.value?.styles?.singleAccordionOpen)
    const showCloseBrowserMessage = computed(() => styleObj.value?.styles?.showCloseBrowserMessage)
    const logoImage = computed(() => {
        // let's check to see if it's a url, otherwise assume it's in the images dir
        let logoImage = styleObj.value?.styles?.logoImage
        if (logoImage?.indexOf('png')) logoImage = require(`@/assets/images/${styleObj.value?.styles?.logoImage}`)
        return logoImage
    })

    const updateHeaderImage = (image) => {
        _headerImage.value = image
    }

    const updateStyleObj = (obj) => {
        _loadingStyle.value = false
        styleObj.value = { ...(obj || {}) }
    }

    return {
        styleObj,
        headerImage,
        loadingStyle,
        styleTheme,
        isDefaultTheme,
        openAllAccordions,
        showFormTitle,
        loadFormStatsOnForm,
        indentQuestionGroupings,
        footerImageUrl,
        defaultSubmitButtonText,
        disableForms,
        singleAccordionOpen,
        logoImage,
        showCloseBrowserMessage,
        updateHeaderImage,
        updateStyleObj
    }
})
