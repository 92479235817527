export default {
    editingForm: (state) => state.editingForm,
    elementToEdit: (state) => state.elementToEdit,
    formTitle: (state) => state.formTitle,
    tenantLogo: (state) => state.tenantLogo,
    category: (state) => state.category,
    iframeMessage: (state) => state.iframeMessage,
    iframeSource: (state) => state.iframeSource,
    constants: (state) => state.constants,
    readOnly: (state) => state.readOnly,
    staticChoices: (state) => state.staticChoices,
    isFullSurvey: (state) => state.isFullSurvey,
    componentData: (state) => state.modal.componentData,
    isVisible: (state) => state.modal.isVisible,
    thankYouRedirectQueryParams: (state) => state.thankYouRedirectQueryParams,
    isExternal: (state) => state.isExternal,
    parentTenantId: (state) => state.parentTenantId,
    previousRoute: (state) => state.previousRoute,
    questionMapping: (state) => {
        return state.questionMapping
    },
    tenant: (state) => state.tenant,
    formHeaderType: (state) => state.formHeaderType,
    showTenantBranding: (state) => state.showTenantBranding,
    isKiosk: (state) => state.isKiosk,
    saveTimeoutQueue: (state) => state.saveTimeoutQueue,
    isSaving: (state) => state.isSaving
}
