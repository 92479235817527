import Vue from 'vue'
import Vuex from 'vuex'

// Base application vue store
import app from './base/index'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app
    }
})
