<script>
import MobileLayout from '@/views/MobileLayout'
import DesktopLayout from '@/views/DesktopLayout'
import Vue from 'vue'

export default {
    functional: true,
    name: "SwitchLayout",
    render(createElement, context) {
        if (Vue.$device.mobile) {
            // TODO: add back mobile site when it's ready
            return createElement(MobileLayout, context.data, context.children)
            // return createElement(ComingSoonLayout, context.data, context.children)
        }
        return createElement(DesktopLayout, context.data, context.children)
    }
}
</script>
