import {goBackToPresurvey} from '@/modules/forms'
import {FIELD_RESET_TIMEOUT, THANKYOU_SURVEY_TIMEOUT} from '@/modules/constants'
import {computed, ref, watch} from '@vue/composition-api'
import SEND_FULL_SURVEY from '@/graphql/mutations/sendFullSurvey.gql'
import {provideApolloClient, useLazyQuery, useMutation} from '@vue/apollo-composable'
import { client } from '@/vue-apollo'
import {GET_FORM_SET} from '@/graphql/queries/formSetQueries.gql'
import {createNamespacedHelpers} from 'vuex-composition-helpers'

const { useGetters } = createNamespacedHelpers('')

export function useTimeout(variables) {
    provideApolloClient(client)
    const { tenant, isKiosk } = useGetters(['tenant', 'isKiosk'])
    const formSetId = ref(null)
    const previousRoute = ref(null)
    const redirectTimeout = ref(null)
    const shortName = ref(null)
    const params = ref(null)
    const router = ref(null)
    const completeSurveyForm = ref(null)
    const redirecting = ref(false)
    const email = ref(''), phone = ref('')
    const formAnswerId = ref('')
    const tenantId = ref('')
    const counter = ref(THANKYOU_SURVEY_TIMEOUT)
    const hasLoaded = ref(false)
    const redirectTo = ref(null)
    
    const tenantIdOrCompare = ref([{
        tenant_id: {_eq: tenant.value?.tenantId}
    }])
    const computedValues = computed(() => {
        return {
            formSetId: formSetId.value,
            tenantIdOrCompare: tenantIdOrCompare.value
        }
    })

    if (tenant.value?.parentTenantId) {
        tenantIdOrCompare.value.push({
            tenant_id: {_eq: tenant.value?.parentTenantId}
        })
    }

    // const tenantIdOrCompare = [{
    //     tenant_id: {_eq: tenantId.value}
    // }]

    const {
        onResult: getFormSetOnResult,
        // onError: getFormSetOnError,
        refetch: getFormSetRefetch,
        load: getFormSetLoad
    } = useLazyQuery(GET_FORM_SET, computedValues)

    const sendSurvey = async () => {
        return new Promise((resolve, reject) => {
            const { onError, mutate, onDone} = useMutation(
                SEND_FULL_SURVEY,
                () => {
                    return {
                        variables: {
                            email: email.value,
                            phone: variables.value?.phone,
                            tenant: variables.value?.tenantId,
                            formId: null,
                            formVersion: null,
                            formAnswerId: variables.value?.formAnswerId,
                            formSetId: '',
                            practitioner: ''
                        }
                    }
                }
            )
            onDone(({ data }) => {
                resolve(data)
            })
            onError(err => {
                reject(err)
            })
            mutate()
        })
    }

    const callBackToPreSurvey = async () => {
        if (redirecting.value) return

        redirecting.value = true
         if (redirectTo.value) return router.value.push({ name: redirectTo.value })
        if (!hasLoaded.value) await getFormSetLoad()
        else await getFormSetRefetch()
    }

    getFormSetOnResult(({ data }) => {
        if (!data) return
        let routeName = previousRoute.value
        const urlSubRoute = data?.formSetObj?.at(0)?.url_sub_route
        //TODO: make the below a constant for now
        if (isKiosk.value) routeName = 'ShortName-Kiosk-DynamicFormView'
        else if (urlSubRoute) routeName = urlSubRoute === 'feedback' ? 'nps-feedback' : 'PatientSurveyView'
        else routeName = 'PatientSurveyView'
        // first let's check the sub route
        goBackToPresurvey(router.value, isKiosk.value, shortName.value, params.value, routeName)
    })

    const runTimeout = () => {
        if (counter.value === null) counter.value = THANKYOU_SURVEY_TIMEOUT
        if (counter.value > 0) {
            redirectTimeout.value = setTimeout(async () => {
                counter.value--
                runTimeout()
            },  1000)
            return
        }
        callBackToPreSurvey()
    }

    const cancelTimeout = () => {
        if (!redirectTimeout.value) return

        clearTimeout(redirectTimeout.value)
        redirectTimeout.value = null
        counter.value = null
    }

    const fieldResetTimeout = () => {
        clearTimeout(redirectTimeout.value)
        redirectTimeout.value = null
        counter.value = FIELD_RESET_TIMEOUT
        runTimeout()
    }

    watch(() => variables.value, (newValue) => {
        email.value = newValue?.email
        phone.value = newValue?.phone
        formAnswerId.value = newValue?.formAnswerId
        tenantId.value = newValue?.tenantId
    }, { deep: true })

    return {
        previousRoute,
        redirectTimeout,
        isKiosk,
        shortName,
        params,
        router,
        completeSurveyForm,
        counter,
        formSetId,
        tenantId,
        redirectTo,
        callBackToPreSurvey,
        runTimeout,
        cancelTimeout,
        sendSurvey,
        fieldResetTimeout
    }
}
