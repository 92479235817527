<template>
    <div>
        <GlobalHeader :title="title" :tenant-logo="logo" />
        <slot />
    </div>
</template>

<script>
import GlobalHeader from '@/components/shared/GlobalHeader'
import {mapGetters} from 'vuex'

export default {
    name: 'FullLayout',
    components: {GlobalHeader},
    // data: () => ({
    //     title: 'Patient Form',
    // }),
    computed: {
        ...mapGetters(['formTitle', 'tenantLogo']),
        title() {
            return this.formTitle || ''
        },
        logo() {
            return this.tenantLogo || ''
        }
    }
}
</script>
