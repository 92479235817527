import {patientSurveyBeforeEnter, setDefaultFormSet, setIsKioskBeforeRoute } from '@/modules/routeHooks'

const kioskRoutes = [
    {
        path: '/Kiosk/:shortName/PreSurvey',
        redirect: '/Kiosk/:shortName/feedback'
    },
    {
        path: '/Kiosk/launch',
        name: 'Kiosk-launch',
        component: () => import(/* webpackChunkName: "DynamicFormView" */ '@/views/KioskLoginView'),
        meta: {
            layout: 'empty',
            headerType: 'nps-header'
        },
        beforeEnter: setIsKioskBeforeRoute

    },
    {
        path: '/Kiosk/:shortName/feedback',
        name: 'kiosk-nps-feedback',
        component: () => import(/* webpackChunkName: "DynamicFormView" */ '@/views/shared/DynamicFormView'),
        meta: {
            layout: 'empty',
            //headerType: 'nps-header',
            headerType: 'survey-header',
            urlRoute: 'feedback'
        },
        beforeEnter: setDefaultFormSet
    },
    {
        path: '/Kiosk/:shortName/PatientSurvey',
        name: 'Kiosk-PreSurveyView',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "patient-survey-view" */ '@/views/shared/PreSurveyView'),
        meta: {
            layout: 'empty',
            urlRoute: 'patientsurvey'
        },
        //TODO: check this works fine
        beforeEnter: patientSurveyBeforeEnter
    },
    {
        path: '/Kiosk/:shortName/DynamicForm',
        name: 'ShortName-Kiosk-DynamicFormView',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "kiosk-dynamic-survey-view" */ '@/views/shared/DynamicFormView'),
        meta: {
            headerType: 'survey-header',
            urlRoute: 'patientsurvey'
        },
        beforeEnter: patientSurveyBeforeEnter
    },
    {
        path: '/Kiosk/:shortName/Form',
        name: 'Kiosk-DynamicFormView',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "pre-survey-view" */ '@/views/shared/DynamicFormView'),
        meta: {layout: 'empty'},
        beforeEnter: (to, from, next) => {
            // we check if the form isn't external and it's not from the actual previous survey AND it's not the full survey
            // if it's not, we go back to the PreSurvey for now...
            to.params.kiosk = true
            if (from.name !== 'Kiosk-PreSurveyView' && !to.query.ext && !to.query.fs) next({name: 'Kiosk-PreSurveyView', query: to.query})
            else next(to.params)
        }
    },
    {
        path: '/Kiosk/:shortName/PatientSurvey/ThankYou',
        name: 'Kiosk-patientsurvey-thank-you',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "form-view" */ '@/views/shared/ThankYouView'),
        meta: {
            layout: 'empty',
            urlRoute: 'patientsurvey'
        },
    },
    {
        path: '/Kiosk/:shortName/NPS',
        name: 'Kiosk-nps-view',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "nps-view" */ '@/views/shared/NpsView'),
    },
    {
        path: '/Kiosk/:shortName/NPS3Q',
        name: 'Kiosk-nps3q-view',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "nps-view" */ '@/views/shared/NpsView'),
    },
    {
        path: '/Kiosk/:shortName/NPSC',
        name: 'Kiosk-npsc-view',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "nps-view" */ '@/views/shared/NpsView')
    },
    {
        path: '/Kiosk/:shortName/NPSC/:formSetId',
        name: 'Kiosk-npsc-3question-view',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "nps-view" */ '@/views/shared/NpsView')
    },
    // {
    //     path: '/Kiosk/:shortName/NPS/:formSetId',
    //     name: 'Kiosk-nps-3question-view',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "nps-view" */ '@/views/shared/NpsView'),
    // },
    {
        path: '/Kiosk/:shortName/NPS/ThankYou',
        name: 'Kiosk-nps-thank-you',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "form-view" */ '@/views/shared/ThankYouView'),
        meta: {layout: 'empty'},
    },
    {
        path: '/Kiosk/:shortName/NPS3Q/ThankYou',
        name: 'Kiosk-nps3q-thank-you',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "form-view" */ '@/views/shared/ThankYouView'),
        meta: {layout: 'empty'},
    },
    {
        path: '/Kiosk/:shortName/ThankYou',
        name: 'Kiosk-thank-you',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "form-view" */ '@/views/shared/ThankYouView'),
        meta: {layout: 'empty'},
    },
    {
        path: '/Kiosk/:shortName/NPS-B/ThankYou',
        name: 'kiosk-npsc-basic-thank-you',
        component: () => import(/* webpackChunkName: "form-view" */ '@/views/shared/ThankYouView'),
        meta: {
            layout: 'empty',
            headerType: 'nps-thankyou'
        },
    },
]

for (const route of kioskRoutes) {
    if (route.beforeEnter) continue

    route.beforeEnter = (to, from, next) => {
        to.params.kiosk = true
        // getting the value PatientSurvey or NPS so we know which form to go back to

        //TODO: find a better spot to do this mapping - maybe a database table
        let routeType = to.path.split('/').at(-1)?.toLowerCase()
        if (routeType === 'feedback') {
            routeType = 'nps'
            to.params.redirectTo = 'nps-feedback'
        }

        if(to.name !== 'Kiosk-thank-you') to.params.routeType = routeType
        next(to.params)
    }
}

export {
    kioskRoutes
}
