<template>
    <div class="layoutHeight text-black w-full">
        <router-view />
    </div>
</template>

<script>
export default {
    name: "MobileLayout",
}
</script>
