import {defineStore} from 'pinia'
import {ref} from '@vue/composition-api'
import { SURVEY_IDLE_TIMEOUT } from '@/modules/constants'
import store from '@/store'

export const useKioskFormStore = defineStore('kiosk-form-store', () => {
    const formRoute = ref({})
    const idleTimeout = ref(null)

    const updateFormRoute = (route) => {
        formRoute.value = {...route}
    }

    const showIdleTimeoutModal = () => {
        store.dispatch('setModalOpen', {
            isVisible: true,
            data: {
                keepOpen: true,
                modalClass: 'x-large-modal x-large-height-modal'
            },
            componentName: 'ModalShowIdleTimeout'
        })
    }

    const startIdleTimeout = () => {
        idleTimeout.value = setTimeout(() => {
            showIdleTimeoutModal()
        }, SURVEY_IDLE_TIMEOUT * 1000)
    }
    const resetIdleTimeout = () => {
        cancelIdleTimeout()
        startIdleTimeout()
    }
    const cancelIdleTimeout = () => {
        if (idleTimeout.value) {
            clearTimeout(idleTimeout.value)
            idleTimeout.value = null
        }
    }

    return {
        formRoute,
        updateFormRoute,
        startIdleTimeout,
        resetIdleTimeout,
        cancelIdleTimeout,
        idleTimeout,
        showIdleTimeoutModal
    }
})