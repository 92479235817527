const SHORT_SURVEY_PHONE_OR_EMAIL_TEXT = "Thank you for agreeing to provide us with additional feedback. Please complete the survey by following the link sent to the email address or phone number you provided.\nIt is feedback like yours that helps us deliver top quality service to all our patients.\n"
const SHORT_SURVEY_NEITHER_TEXT = "We would love to hear more about your experience at <TENANT_NAME>. If you wish to provide us with additional feedback, simply scan the QR code again and select to take the longer survey.\nIt is feedback like yours that helps us deliver top quality service to all our patients."
const LONG_SURVEY_TEXT = "Thank you for completing the survey. It is feedback like yours that helps us deliver top quality service to all our patients."
const PRE_SURVEY_TEXT = 'Thank you for helping us improve'
const NPS_SURVEY_TEXT = `We’d love to know more about your visit. Please leave your phone number or email address and we’ll send you a short survey so we can make your next visit even better.`
const NPS_SURVEY_NO_PHONE_TEXT = `Thank you for letting us know about your visit. Your feedback is important to us as it helps us to review and create a better experience for you.`
const NPS_COMPLETE_SURVEY_TEXT = `Thank you for completing this survey, if you would like one of our helpful staff to contact you, please enter your details below. <b>These surveys are completely anonymous.</b>`
const NPS_REFERRER_TEXT = 'You may close this browser tab at any time'
const UCC_FEEDBACK_TEXT = `Feel free to close this window whenever you're ready. Have a great day!`

const GRAPHQL_HTTP = process.env.VUE_APP_GRAPHQL_HTTP || ''
const GRAPHQL_WS = process.env.VUE_APP_GRAPHQL_WS || ''
const ADMIN_SECRET = process.env.VUE_APP_HASURA_ADMIN_SECRET || ''
const API_KEY_SECRET = process.env.VUE_APP_BE_API_KEY || ''
const SURVEY_IDLE_TIMEOUT = process.env.VUE_APP_SURVEY_IDLE_TIMEOUT || 10
const MODAL_DISMISS_TIMEOUT = process.env.VUE_APP_MODAL_DISMISS_TIMEOUT || ''
const THANKYOU_SURVEY_TIMEOUT = process.env.VUE_APP_THANKYOU_SURVEY_TIMEOUT || 10
const FIELD_RESET_TIMEOUT = process.env.VUE_APP_FIELD_RESET_TIMEOUT || 30
const DEBUG = process.env.VUE_APP_DEBUG
const DEFAULT_SHORTNAME = process.env.VUE_APP_DEFAULT_SHORTNAME || ''
export const SURVEY_ENGINE_API_HTTP = process.env.VUE_APP_SURVEY_ENGINE_API_HTTP || ''
// export const SURVEY_ENGINE_API_HTTP = 'http://localhost:3001/api/v1'


const DEFAULT_FORM_TITLE = 'Full Survey'

const LONG_SURVEY_TYPE = 'LONG_SURVEY'

const VALUE_MAPPING = {
    'YES': true,
    'NO': false,
    'REFUSED_TO_ANSWER': false,
    'N/A': false
}

const FACE_VALUE_MAPPING = {
    '0': 'N/A',
    '1': 'Poor',
    '2': 'Ok',
    '3': 'Fair',
    '4': 'Very good',
    '5': 'Great',
}

const FORM_STATUS = {
    Incomplete: 'INCOMPLETE',
    Complete: 'COMPLETE',
    Finalized: 'FINALIZED'
}

const FORM_TYPES = {
    Wizard: 'WIZARD'
}

export {
    DEBUG,
    SHORT_SURVEY_PHONE_OR_EMAIL_TEXT,
    SHORT_SURVEY_NEITHER_TEXT,
    LONG_SURVEY_TEXT,
    NPS_SURVEY_TEXT,
    GRAPHQL_HTTP,
    GRAPHQL_WS,
    ADMIN_SECRET,
    API_KEY_SECRET,
    SURVEY_IDLE_TIMEOUT,
    MODAL_DISMISS_TIMEOUT,
    THANKYOU_SURVEY_TIMEOUT,
    VALUE_MAPPING,
    PRE_SURVEY_TEXT,
    DEFAULT_FORM_TITLE,
    FIELD_RESET_TIMEOUT,
    FACE_VALUE_MAPPING,
    FORM_STATUS,
    NPS_COMPLETE_SURVEY_TEXT,
    NPS_SURVEY_NO_PHONE_TEXT,
    FORM_TYPES,
    NPS_REFERRER_TEXT,
    DEFAULT_SHORTNAME,
    LONG_SURVEY_TYPE,
    UCC_FEEDBACK_TEXT
}
