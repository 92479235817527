<template lang="pug">
    div.text-xl.pt-8
        | {{ timeRef }}
</template>

<script>
import {onMounted, ref, watch} from '@vue/composition-api'
import {addSeconds, format} from 'date-fns'

export default {
    name: 'BaseTimer',
    props: {
        seconds: {
            type: Number,
            default: 0
        }
    },
    setup(props) {
        const timeRef = ref('')

        onMounted(() => {
            updateTimeRef(props.seconds)
        })

        const updateTimeRef = (val) => {
            const date = new Date(0)
            const helperDate = addSeconds(date, val)
            timeRef.value = format(helperDate, 'mm:ss')
        }

        watch(() => props.seconds, (nextValue) => {
            updateTimeRef(nextValue)
        })

        return {
            timeRef
        }
    }
}
</script>
