const PromiseResolveEnum = {
    NO_FUNC_FOUND: 'no_func_found',
    NO_QUESTIONS_FOUND: 'no_questions_found'
}

const ConstantTypeEnum = {
    Age: 'AGE',
    SurveyType: 'SURVEY_TYPE',
    Gender: 'GENDER'
}

const ErrorCodes = {
    IncorrectReferral: 'incorrect_referral',
    IncorrectRouteParams: 'incorrect_route_params',
    FormSetNoLongerInUse: 'form_set_no_longer_in_use'
}

export {
    PromiseResolveEnum,
    ConstantTypeEnum,
    ErrorCodes
}
