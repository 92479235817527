<template lang="pug">
    div(class="m-auto w-full h-full")
        .px-4.h-full
            .text-center
                v-card-title
                    span.mx-auto.font-semibold
                        | View Scores
                div(
                    class="w-full grid grid-cols-1 lg:grid-cols-1 py-4 score-table"
                )
                    .score-table.grid.grid-cols-4.gap-4.py-2.border-bottom
                        .text-grey-2.text-left.text-uppercase.font-weight-bold
                            | Name
                        .text-grey-2.text-left.text-uppercase.font-weight-bold
                            | Score
                        .text-grey-2.text-left.text-uppercase.font-weight-bold.col-span-2
                            | Category
                    .grid.grid-cols-4.gap-4.py-2.border-bottom(v-for="scoreObj of scores")
                        .text-left(v-if="showScore(scoreObj.form)")
                            | {{ scoreObj.form.title }}
                        .pl-4.text-left(v-if="showScore(scoreObj.form)")
                            | {{ scoreObj.score }}
                        .col-span-2.text-left(v-if="showScore(scoreObj.form)")
                            | {{ getFormCategory(scoreObj.form, scoreObj.score) }}
                            v-expansion-panels(v-if="haveCategories(scoreObj.form)")
                                v-expansion-panel
                                    v-expansion-panel-header.pl-0.text-blue-400.font-weight-bold.text-uppercase
                                        | Scoring categories
                                    v-expansion-panel-content.no-pl.no-pt
                                        div(
                                            class="flex flex-row text-grey-1"
                                            v-for="(category, idx) of getFormCategories(scoreObj.form)"
                                            :key="idx"
                                        )
                                            .pr-2.w-12
                                                | {{ category[0] }}
                                            .px-2
                                                | {{ category[1] }}

            .flex.flex-row.justify-center.mt-4
                v-btn.mr-4.w-40(depressed, @click="dismissHandler") Cancel
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import {createNamespacedHelpers} from 'vuex-composition-helpers'

const { useActions, useGetters } = createNamespacedHelpers('')

export default {
    name: 'ModalShowScore',
    setup() {
        const { setModalClose } = useActions(['setModalClose'])
        const { componentData } = useGetters(['componentData'])
        const scores = ref(0)
        const dismissHandler = () => {
            setModalClose()
        }

        const showScore = (form) => {
            // we only show the score if we have categories to show for now
            // return !!form?.form_type?.categories
            return !!form?.is_scorable
        }

        const haveCategories = (form) => {
            if (!form.form_type?.categories) return false
            const categories = form.form_type?.categories
            return categories.scoring_categories instanceof Object
        }

        const sortCategories = (categories) => {
            return categories.sort((a, b) => {
                // console.log('a and b', a, b)
                if (a[0].indexOf('+') > -1) return 1
                if (b[0].indexOf('+') > -1) return -1

                if (a[0] < b[0]) return -1
                if (a[0] > b[0]) return 1
                return 0
            })
        }

        const getFormCategories = (form) => {
            const categories = form.form_type?.categories?.scoring_categories
            if (categories instanceof Object) {
                let entries = Object.entries(categories)
                // console.log('entries', entries)
                entries = sortCategories(entries)
                return entries
            }
            return []
        }

        const getFormCategory = (form, score) => {
            if (!form.form_type?.categories) return ''
            const categories = form.form_type?.categories
            if (categories.scoring_categories instanceof Object) {
                let key = null
                //let's check the last key, if it is a range or there's a '+' then we need to change the logic
                let keys = Object.keys(categories.scoring_categories)

                // we need to sort the keys properly since when we first get the list
                // 6+ is at the top of the list, before 2-3 (for example)
                keys = sortCategories(keys)

                if (keys?.at(0)?.indexOf('-') > -1) {
                    for (const v of keys) {
                        const [min, max] = v.split('-')
                        if (score >= Number(min) && score <= Number(max)) key = v
                    }
                // else if branch for the case where we have a #+ scenario, for the default more than value
                }
                if (keys?.at(-1)?.indexOf('+') > -1) {
                    const strScore = `${Math.round(score)}`
                    const lastNum = keys?.at(-1).split('+')?.at(0)
                    for (const v of keys) {
                        if (strScore === key) key = v
                    }
                    if (key === null && score >= Number(lastNum)) key = keys?.at(-1)
                }
                console.log('====== key to look for with the +', keys.at(-1))

                console.log('====== key and category found, plus the score', key, score)
                if (key) return `${categories.scoring_categories[key]}`
                else return 'N/A'
            } else if (typeof categories.scoring_categories === 'string') {
                return categories.scoring_categories
            }
        }

        watch(componentData, (newValue) => {
            console.log('========== value from the modal', componentData, newValue)
            scores.value = [...newValue.scores]
        }, { immediate: true })

        return {
            scores,
            showScore,
            dismissHandler,
            getFormCategory,
            haveCategories,
            getFormCategories
        }
    },
}
</script>
