import {defineStore} from 'pinia'
import {computed, ref} from '@vue/composition-api'

export const useTenantStore = defineStore('tenant', () => {
    const _tenant = ref({})

    const computedTenant = computed(() => _tenant.value)

    const updateTenant = (tenant) => {
        _tenant.value = tenant
    }

    return { computedTenant, updateTenant }
})