import gql from 'graphql-tag'

const GET_FORM_SET_BY_ID = gql`
    query getFormSetById($formSetId: uuid!, $tenantIdOrCompare: [form_builder_hasura_form_set_bool_exp!] = []) {
    formSetObj: form_builder_hasura_form_set(
        where: {
            _and: {
                form_set_id: {_eq: $formSetId},
                _or: $tenantIdOrCompare
            }
        }
    ) {
        created_at
        form_qr_code_id
        form_set_id
        form_type_id
        is_primary
        last_updated
        name
        tenant_id
        survey_type
        published
        forms {
            _order
            form_set_form_id
            form_set_id
            form_id
            form_version
            tenant_id
            form_set {
                name
            }
            form {
                tenant_id
                form_id
                version
                actions
                title
                icon_name
                css_classes
                form_type_id
                survey_type
                form_type_id
                calc_function_id
                calc_function {
                    calc_function_id
                    name
                }
                form_type {
                    form_type_id
                    type
                }
            }
        }
    }
}
`

const GET_FORM_SET_ID_BY_SHORT_NAME = gql`
    query getFormSetIdByShortName($tenantIdOrCompare: [form_builder_hasura_tenant_bool_exp!] = []) {
        formSets: form_builder_hasura_form_set(
            where: {
                _and: {
                    tenant: {
                        _or: $tenantIdOrCompare
                    },
                    published: {
                        _eq: true
                    },
                    is_primary: {
                        _eq: true
                    }
                }
            },
            order_by: {
                tenant: {parent_tenant_id: desc_nulls_last}
            }
        ) {
            form_set_id
            url_sub_route
            published
            form_type_id
            form_type {
                form_type_id
                name
                type       
            }
        }
    }
`
const GET_FORM_SET_ID_BY_SHORT_NAME_AND_ID = gql`
    query getFormSetIdByShortName($formSetId: uuid!, $tenantIdOrCompare: [form_builder_hasura_tenant_bool_exp!] = []) {
        formSets: form_builder_hasura_form_set(
            where: {
                _and: {
                    form_set_id: {
                        _eq: $formSetId
                    },
                    tenant: {
                        _or: $tenantIdOrCompare
                    },
                    published: {
                        _eq: true
                    }
                }
            },
            order_by: {
                tenant: {parent_tenant_id: desc_nulls_last}
            }
        ) {
            form_set_id
            published
            url_sub_route
            form_type {
                form_type_id
                name
                type       
            }
        }
    }
`

const GET_FORM_SET = gql`
    query getFormSet($formSetId: uuid!, $tenantIdOrCompare: [form_builder_hasura_form_set_bool_exp!] = []) {
        formSetObj: form_builder_hasura_form_set(
            where: {
                _and: {
                    form_set_id: {_eq: $formSetId},
                    _or: $tenantIdOrCompare
                }
            }
        ) {
            form_set_id
            url_sub_route
            form_type_id
            is_primary
            last_updated
            name
            tenant_id
            survey_type
            published
            linked_form_set_id
        }
    }
`

const GET_FORM_SET_TYPES = gql`
    query getFormSetIdByShortName($tenantId: uuid = "") {
        form_types: form_builder_hasura_form_type(
            where: {
                tenant_id: {_eq: $tenantId}
            }
        ) {
            categories
            form_type_id
            name
            type
        }
    }
`


export {
    GET_FORM_SET_BY_ID,
    GET_FORM_SET_ID_BY_SHORT_NAME,
    GET_FORM_SET,
    GET_FORM_SET_ID_BY_SHORT_NAME_AND_ID,
    GET_FORM_SET_TYPES
}
