import { getOrCreateProvider } from '@/vue-apollo'
import GET_CONSTANTS from '@/graphql/queries/getConstants.gql'
import camelcaseKeys from 'camelcase-keys'
import Vue from 'vue'
import staticChoices from '@/json/staticChoices.json'
import { GET_TENANT_BY_SHORT_NAME } from '@/graphql/queries/tenantQueries.gql'
import {GET_FORM_SET_ID_BY_SHORT_NAME, GET_FORM_SET_ID_BY_SHORT_NAME_AND_ID} from '@/graphql/queries/formSetQueries.gql'
import {getTenantIdOrCompare} from '@/utils/graphqlVariableHelpers'
import {useTenantStylesStore} from '@/stores/useTenantStylesStore'
import { tenantService } from '@/services/tenant.service'


const apolloClient = getOrCreateProvider()?.defaultClient

export default {
    setTenantId($store, { tenantId }) {
        $store.commit('SET_TENANT_ID', tenantId)
    },
    setEditingForm($store, editingForm) {
        $store.commit('SET_EDITING_FORM', editingForm)
    },
    setElementToEdit($store, { element, index }) {
        $store.commit('SET_ELEMENT_TO_EDIT', { element, index })
    },
    setFormTitle($store, title) {
        $store.commit('SET_FORM_TITLE', title)
    },
    setTenantLogo($store, logo) {
        $store.commit('SET_TENANT_LOGO', logo)
    },
    setIsFullSurvey($store, isFullSurvey) {
        $store.commit('SET_IS_FULL_SURVEY', isFullSurvey)
    },
    setCategory($store, category) {
        $store.commit('SET_CATEGORY', category)
    },
    setIframeMessage($store, iframeMessage) {
        $store.commit('SET_IFRAME_MESSAGE', iframeMessage)
    },
    setIframeSource($store, iframeSource) {
        $store.commit('SET_IFRAME_SOURCE', iframeSource)
    },
    setReadOnly($store, readOnly) {
        $store.commit('SET_READ_ONLY', readOnly)
    },
    setThankYouRedirectQueryParams($store, params) {
        $store.commit('SET_THANK_YOU_QUERY_PARAMS', params)
    },
    setIsExternal($store, isExternal) {
        $store.commit('SET_IS_EXTERNAL', isExternal)
    },
    setParentTenantId($store, parentTenantId) {
        $store.commit('SET_PARENT_TENANT_ID', parentTenantId)
    },
    setPreviousRoute($store, prevRoute) {
        $store.commit('SET_PREVIOUS_ROUTE', prevRoute)
    },
    setQuestionMapping($store, questionMapping) {
        $store.commit('SET_QUESTION_MAPPING', questionMapping)
    },
    setTenant($store, tenant) {
        $store.commit('SET_TENANT', tenant)
    },
    updateQuestionMapping($store, {questionId, value}) {
        // console.log('updaitng mapping with the following', questionId, value)
        if (questionId) $store.commit('UPDATE_QUESTION_MAPPING', {questionId, value})
    },
    addToSaveTimeoutQueue($store, callback) {
        // console.log('updaitng mapping with the following', questionId, value)
        if (callback) $store.commit('ADD_TO_SAVE_TIMEOUT_QUEUE', callback)
    },
    clearSaveTimeoutQueue($store) {
        $store.commit('CLEAR_SAVE_TIMEOUT_QUEUE')
    },
    updateIsSaving($store, isSaving) {
        $store.commit('UPDATE_IS_SAVING', isSaving)
    },
    setShowTenantBranding($store, showTenantBranding) {
        $store.commit('SET_SHOW_TENANT_BRANDING', showTenantBranding)
    },
    setIsKiosk($store, isKiosk) {
        $store.commit('SET_IS_KIOSK', isKiosk)
    },
    fetchConstants(store) {
        // if we are not logged in then get the default static choices - which should only have the user title
        return apolloClient.defaultClient.query({
            query: GET_CONSTANTS,
        })
            .then(({ data: { constants } }) => {
                constants = camelcaseKeys(constants || [], {deep: true})

                store.commit('SET_CONSTANTS', constants)
            })
            .catch((err) => {
                console.log('something went wrong', err)
                // if we didn't get the permissions then it means we can't view this practice
                Vue.router.push({name: 'error'})
            })
    },
    fetchStaticChoices(store) {
        const data = staticChoices
        let newChoices = {}
        const keys = Object.keys(data)
        for (let key of keys) {
            newChoices[key] = data[key].map((item) => {
                return {
                    value: item[0],
                    label: item[1],
                    text: item[1],
                }
            })
        }
        store.commit('SET_STATIC_OBJECT', newChoices)
    },
    setModalOpen($store, modalData) {
        $store.commit('MODAL_IS_VISIBLE', modalData?.isVisible)
        $store.commit('MODAL_DATA', modalData?.data || {})
        $store.commit('MODAL_COMPONENT_NAME', modalData?.componentName || '')
    },
    setModalClose($store) {
        $store.commit('MODAL_IS_VISIBLE', false)
        $store.commit('MODAL_DATA', {})
        $store.commit('MODAL_COMPONENT_NAME', '')
    },
    async fetchTenant($store, tenantId) {
        const tenantStylesStore = useTenantStylesStore()

        let tenant = null
        try {
            const tenant = await tenantService.getTenantById(tenantId)
            tenantStylesStore.updateStyleObj(tenant.style)

            await $store.commit('SET_TENANT', tenant)
        } catch (error) {
            console.log('error loading tenant', error)
            //TODO: do seomthing on this error
        }

        return tenant
    },
    async getTenantByShortName($store, shortName) {
        const tenantStylesStore = useTenantStylesStore()

        let tenant = null
        try {

            const response = await apolloClient.query({
                query: GET_TENANT_BY_SHORT_NAME,
                variables: { shortName }
            })
            tenant = camelcaseKeys(response.data?.tenant?.at(0) || {}, {deep: true})
            tenantStylesStore.updateStyleObj(tenant.style)
            await $store.commit('SET_TENANT', tenant)
        } catch (error) {
            console.log('error loading tenant', error)
        }
        return tenant
    },
    async fetchPrimaryFormSet($store, { tenantId, shortName, parentId = null , metaUrlRoute = null}) {
        let formSet = null

        const tenantIdOrCompare = getTenantIdOrCompare(shortName, tenantId, parentId)
        try {
            let { data } = await apolloClient.query({
                query: GET_FORM_SET_ID_BY_SHORT_NAME,
                variables: {
                    tenantIdOrCompare
                }
            })
            const formSets = camelcaseKeys(data?.formSets || [], { deep: true })
            if (formSets instanceof Array && formSets?.length > 0) {
                //first checking if we have any that have the url so we go to that one first, otherwise just take the first one
                formSet = formSets.find(fs => fs.urlSubRoute === metaUrlRoute) || formSets[0]
            }
        } catch (e) {
            console.log('error from call', e)
        }
        return formSet || {}
    },
    async fetchFormSet($store, { tenantId, shortName, parentId = null , formSetId = null}) {
        let formSet = null
        if (!formSetId) return formSet

        const tenantIdOrCompare = getTenantIdOrCompare(shortName, tenantId, parentId)
        try {
            let { data } = await apolloClient.query({
                query: GET_FORM_SET_ID_BY_SHORT_NAME_AND_ID,
                variables: {
                    formSetId,
                    tenantIdOrCompare
                }
            })
            const formSets = camelcaseKeys(data?.formSets || [], { deep: true })
            if (formSets instanceof Array && formSets?.length > 0) {
                //first checking if we have any that have the url so we go to that one first, otherwise just take the first one
                formSet = formSets.at(0)
            }
        } catch (e) {
            console.log('error from call', e)
        }
        return formSet
    },
    async fetchFormSetUrl($store, { tenantId, isKiosk, shortName, parentId = null , metaUrlRoute = null}) {
        let url = null

        const tenantIdOrCompare = [{
            short_name: {
                _eq: shortName
            }
        }]
        if (tenantId) {
            tenantIdOrCompare.push({
                tenant_id: {
                    _eq: tenantId
                }
            })
        }
        if (parentId) {
            tenantIdOrCompare.push({
                tenant_id: {
                    _eq: parentId
                }
            })
        }
        try {
            let formSet = null
            let { data } = await apolloClient.query({
                query: GET_FORM_SET_ID_BY_SHORT_NAME,
                variables: {
                    tenantIdOrCompare
                }
            })
            const formSets = camelcaseKeys(data?.formSets || [], { deep: true })
            if (formSets instanceof Array && formSets?.length > 0) {
                //first checking if we have any that have the url so we go to that one first, otherwise just take the first one
                formSet = formSets.find(fs => fs.urlSubRoute === metaUrlRoute) || formSets[0]
            }
            // checking if the route is for the Kiosk
            if (formSet?.urlSubRoute) {
                url = `${shortName}/${formSet.urlSubRoute}`
                if (isKiosk) url = `/Kiosk/${url}`
                else url = `/${url}`
            } else if (formSet?.formSetId) {
                // if we don't have a urlSubRoute, just tack on the formSetId to the end of the route
                url = `${shortName}/DynamicForm?fsi=${formSet.formSetId}`
                if (isKiosk) url = `/Kiosk/${url}`
                else url = `/${url}`
            }

        } catch (e) {
            console.log('error from call', e)
        }
        return url
    }
}
