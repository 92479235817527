<template>
    <v-menu
        ref="dateMenu"
        v-show="show"
        v-model="dateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                ref="date"
                v-model="computedDate"
                :label="label"
                v-bind="attrs"
                v-on="on"
                :rules="rules"
                :hint="hint"
                :persistent-hint="showHint"
                :placeholder="placeholder"
                :disabled="disabled"
                @change="dateChanged"
            ></v-text-field>
        </template>
        <v-date-picker
            color="bg-white-pure"
            ref="datePicker"
            :max="max"
            v-model="date"
            v-bind="$attrs"
            @change="save"
        ></v-date-picker>
    </v-menu>
</template>

<script>
import {format, formatISO, isValid, parse, parseISO} from 'date-fns'

export default {
    name: 'EditableDatePicker',
    props: {
        value: {
            type: String,
            default: ''
        },
        defaultDate: {
            type: Date,
            default: () => {}
        },
        hint: {
            type: String,
            default: '',
        },
        showHint: {
            type: Boolean,
            default: false,
        },
        rules: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        show: {
            type: Boolean,
            default: true,
        },
        disableFutureDate: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            date: this.value,
            dateMenu: false,
            manuallyEnteredDate: null,
            valueChanged: false
        }
    },
    mounted() {
        if (this.defaultDate && (this.value == null || this.date == undefined || !Object.keys(this.value).length)) this.date = format(this.defaultDate, 'yyyy-MM-dd')
    },
    methods: {
        validate() {
            this.isValid = this.$refs.date.validate()
            return this.isValid
        },
        save(date) {
            this.valueChanged = true
            this.$refs.dateMenu.save(date)
            this.dateMenu = false
            this.emitChange()
        },
        dateChanged() {
            if (!this.manuallyEnteredDate) return

            this.valueChanged = true
            const date = parse(this.manuallyEnteredDate, 'dd/MM/yyyy', new Date())
            if (isValid(date)) {
                this.date = format(date, 'yyyy-MM-dd')
                this.manuallyEnteredDate = null
            }

            this.emitChange()
        },
        emitChange() {
            this.$emit('input', this.date)
            this.$emit('change', this.date)
        }
    },
    computed: {
        computedDate: {
            get() {
                if (!this.date) return ''
                return format(parseISO(this.date), 'dd/MM/yyyy')
            },
            set(val) {
                this.manuallyEnteredDate = val
            }
        },
        max() {
            return this.disableFutureDate ? formatISO(new Date()) : null
        },
    },
    watch: {
        value() {
            this.date = this.value
        },
        date() {
            if (this.valueChanged) this.$emit('value-changed', this.date)
        }
    },
}
</script>
