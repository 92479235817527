import {ErrorCodes} from '@/utils/enums'
import store from '@/store'
import camelcaseKeys from 'camelcase-keys'
import {DEFAULT_SHORTNAME, LONG_SURVEY_TYPE} from '@/modules/constants'
import { formSetService } from '@/services/form-set.service'
import { tenantService } from '../services/tenant.service'
import { useTenantStylesStore } from '../stores/useTenantStylesStore'

const patientSurveyBeforeEnter = async (to, from, next) => {
    // checking to see if we need to force the load of the current route
    // and uf we have the short name, if we have the shortname already, then continue
    // console.log('======= to and from', to, from)
    
    const forceLoad = to.query?.f
    let shortName = to.params?.shortName
    let changeTenantId = false
    const isKiosk = to.params?.isKiosk || to.path.toLowerCase().indexOf('kiosk') > -1
    await store.dispatch('setIsKiosk', isKiosk)

    // if (forceLoad || shortName) return next()
    if (forceLoad || (forceLoad && shortName)) return next()

    // otherwise we get the tenant and redirect to the shortname
    // if we get to here, we get the tenantId
    let tenantId = to.query?.tenant

    if (!tenantId && !shortName) return next({name: 'error', params: {type: ErrorCodes.IncorrectRouteParams}})

    let foundTenant = null, tenant = null
    if (tenantId) {
        foundTenant = await store.dispatch('fetchTenant', tenantId)
        tenant = camelcaseKeys(foundTenant || {}, {deep: true})
        if (!shortName) shortName = tenant.shortName

        // if we don't have a tenant now, let's set the default
        if (!shortName) {
            shortName = DEFAULT_SHORTNAME
            changeTenantId = true
        }
    }

    if (!tenant?.tenantId && shortName) {
        tenant = await store.dispatch('getTenantByShortName', shortName)
    }

    if (changeTenantId && tenant?.tenantId) tenantId = tenant?.tenantId
    const metaUrlRoute = to?.meta?.urlRoute

    // we check if we have a LONG_SURVEY and short circuit or if we have the kiosk version
    const surveyFormRoute = 'ShortName-DynamicFormView'
    const surveyFormRoutes = [surveyFormRoute, 'ShortName-Kiosk-DynamicFormView']
    
    // let's get the formSet early so we can check if we have to go to the 'formNoLongerInUse error page
    let formSet
    // if we have the formset id, we call the fetchFormSet function, otherwise we get the primary
    if (to.query?.fsi) {
        formSet = await store.dispatch('fetchFormSet', {
            tenantId, shortName, parentId: tenant?.parentTenant?.tenantId, formSetId: to.query?.fsi
        })
    } else {
        formSet = await store.dispatch('fetchPrimaryFormSet', {
            tenantId, shortName, parentId: tenant?.parentTenantId, metaUrlRoute: null
        })
    }
    

    // if the form_set is not published, then we go to the error page
    if (!formSet?.published) return next({name: 'error', params: {type: ErrorCodes.FormSetNoLongerInUse}})

    if (to.query?.fsi && surveyFormRoutes.indexOf(to?.name) === -1) {
        // the 'f=' param forces the form to load since we know what we're doing
        const toNextRoute = {
            name: surveyFormRoute, meta: to.meta, params: { ...to.params, f: true }, query: to.query
        }
        if (formSet?.formType?.type === LONG_SURVEY_TYPE) return next(toNextRoute)
    }

    // adding short circuit, if we have the form_set_id and the tenant already, we can just go to the route
    if (to.query?.fsi && metaUrlRoute === 'patientsurvey') return next()
    if (to.query?.fsi && metaUrlRoute === 'patientsurvey-old') return next({name: 'error', params: {type: ErrorCodes.IncorrectRouteParams}})
    // now we check to see if we have the sub route
    const formSetUrl = await store.dispatch('fetchFormSetUrl', { tenantId, isKiosk, shortName, parentId: tenant?.parentTenant?.tenantId})
    // if we have a urlSubRoute then we go to that one, otherwise keep going with the original flow
    if (formSetUrl) return next(formSetUrl)
    //TODO: check if we need this
    else if (shortName) return next()

    // now we check to see if we have the sub route
    const { formSetId } = await store.dispatch('fetchPrimaryFormSet', { tenantId, shortName, parentId: tenant?.parentTenant?.tenantId, metaUrlRoute})

    if (!tenant && !shortName) next({name: 'error', params: {type: ErrorCodes.IncorrectRouteParams}})
    const name = isKiosk ? 'ShortName-Kiosk-DynamicFormView' : shortName ? 'PatientSurveyView' : 'ShortName-DynamicFormView'
    return next({ name, params: { 'shortName': tenant?.shortName || shortName, fsi: formSetId }, meta: to.meta })
}

// TODO: change to 'get feedback formset' or something of the like
const setDefaultFormSet = async (to, from, next) => {
    // checking to see if we need to force the load of the current route
    // and uf we have the short name, if we have the shortname already, then continue
    if (to.name && to.params.fsi) return next()

    let shortName = to.params?.shortName
    let changeTenantId = false
    const isKiosk = to.params?.isKiosk || to.path.toLowerCase().indexOf('kiosk') > -1
    await store.dispatch('setIsKiosk', isKiosk)

    let routeType = to.path.split('/').at(-1)?.toLowerCase()
    if (routeType === 'feedback') {
        routeType = 'nps'
        if (isKiosk) to.params.redirectTo = 'kiosk-nps-feedback'
        else to.params.redirectTo = 'nps-feedback'
    }

    // otherwise we get the tenant and redirect to the shortname
    // if we get to here, we get the tenantId
    let tenantId = to.query?.tenant

    if (!tenantId && !shortName) return next({name: 'error', params: {type: ErrorCodes.IncorrectRouteParams}})

    let foundTenant = null, tenant = null
    if (tenantId) {
        foundTenant = await store.dispatch('fetchTenant', tenantId)
        tenant = camelcaseKeys(foundTenant || {}, {deep: true})
        if (!shortName) shortName = tenant.shortName

        // if we don't have a tenant now, let's set the default
        if (!shortName) {
            shortName = DEFAULT_SHORTNAME
            changeTenantId = true
        }
    }

    if (!tenant?.tenantId && shortName) {
        tenant = await tenantService.getTenantByShortName(shortName)
        const tenantStylesStore = useTenantStylesStore()
        tenantStylesStore.updateStyleObj(tenant.style)
    }

    if (changeTenantId && tenant?.tenantId) tenantId = tenant?.tenantId
    // lets get the feedback formSetId now
    const formSetId = await formSetService.getFeedbackFormSetId(tenant?.tenantId)
    const metaUrlRoute = to?.meta?.urlRoute

    if (metaUrlRoute.toLowerCase().indexOf('patientsurvey') > -1) return next({name: 'error', params: {type: ErrorCodes.IncorrectRouteParams}})
    if (!tenant && !shortName) next({name: 'error', params: {type: ErrorCodes.IncorrectRouteParams}})

    return next({ name: to.name, params: { ...to.params, 'shortName': tenant?.shortName || shortName, fsi: formSetId } })
}

const setIsKioskBeforeRoute = async (to, _, next) => {
    const isKiosk = to.params?.isKiosk || to.path.toLowerCase().indexOf('kiosk') > -1
    await store.dispatch('setIsKiosk', isKiosk)

    return next()
}

export {
    patientSurveyBeforeEnter,
    setDefaultFormSet,
    setIsKioskBeforeRoute
}
